<template>
  <component :is="tokenData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="tokenData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching token data
      </h4>
      <div class="alert-body">
        No token found with this token id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-tokens-list'}"
        >
          Token List
        </b-link>
        for other tokens.
      </div>
    </b-alert>

 
      <token-edit-tab-account
        v-if="tokenData"
        :token-data="tokenData"
        :operation-types-options="allOperationTypes"
        class="mt-2 pt-75"
      />
  </component>
</template>

<script>
import {
   BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import tokenStoreModule from '../tokenStoreModule'
import TokenEditTabAccount from './TokenEditTabAccount.vue'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    TokenEditTabAccount
  },
  setup() {
    const tokenData = ref(null)

    const TOKEN_APP_STORE_MODULE_NAME = 'app-token'
    // Register module
    if (!store.hasModule(TOKEN_APP_STORE_MODULE_NAME)) store.registerModule(TOKEN_APP_STORE_MODULE_NAME, tokenStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TOKEN_APP_STORE_MODULE_NAME)) store.unregisterModule(TOKEN_APP_STORE_MODULE_NAME);
    })

    store.dispatch('app-token/fetchToken', { id: router.currentRoute.params.id })
      .then(response => { 
        tokenData.value = response.data;
      
      })
      .catch(error => {
        if (error.response.status === 404) {
          tokenData.value = undefined
        }
      })
    const allOperationTypes = [
      { label: 'All', value: 'ALL' },
      { label: 'Image search', value: 'IMAGE_SEARCH' },
      { label: 'Image editor', value: 'IMAGE_EDITOR' },
      { label: 'Contilt scheme', value: 'CONTILT_SCHEME' },
    ]
    return {
      tokenData,
      allOperationTypes
    }
  },
}
</script>

<style>

</style>
